import getConfig from 'next/config';

export type RuntimeEnvs = {
  publicRuntimeConfig: {
    NEXT_API_URL: string;
    NEXT_PUBLIC_BUCKET_URL: string;
    MICRO_FRONT_EMPLOYEE_URL: string;
    MICRO_FRONT_AUTH_URL: string;
    MICRO_FRONT_LICENSE_URL: string;
    MICRO_FRONT_CARD_URL: string;
    MICRO_FRONT_RECHARGE_URL: string;
    MICRO_FRONT_FINANCIAL_URL: string;
    MICRO_FRONT_IFOOD_CARD_URL: string;
    MICRO_FRONT_NOTIFICATION_URL: string;
    MICRO_FRONT_TICKET_URL: string;
    MICRO_FRONT_MARKETPLACE_URL: string;
    MICRO_FRONT_COLLECTIVE_AGREEMENT_URL: string;
    BFF_IFOODOFFICE_BENEFITS_B2B_API: string;
    BFF_URL: string;
    PWA: string;
    NEXT_PUBLIC_ENV: string;
    NEXT_PUBLIC_FASTER_KEY: string;
    NEXT_PUBLIC_FASTER_SECRET_KEY: string;
    NEXT_PUBLIC_AMPLITUDE_API_KEY: string;
    NEXT_PUBLIC_GA_TRACKING_ID: string;
    NEXT_PUBLIC_WITH_MIRAGE: string;
    NEXT_PUBLIC_SENTRY_RELEASE: string;
    NEXT_PUBLIC_SENTRY_DSN: string;
    NEXT_PUBLIC_SENTRY_PLATFORM_DSN: string;
    NEXT_PUBLIC_HOTJAR_ID: number;
    NEXT_PUPLIC_CLARITY_ID: string;
    WITH_MIRAGE: string;
    ZENDESK_TOKEN: string;
    ZENDESK_CHATBOT_TOKEN: string;
    ZENDESK_SUNSHINE_CHATBOT_TOKEN: string;
    INCOGNIA_APP_ID: string;
    NEXT_PUBLIC_SALESFORCE_URL: string;
    GTM_ID: string;
  };
};

const { publicRuntimeConfig: variables }: RuntimeEnvs = getConfig();

export { variables };

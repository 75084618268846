/* eslint-disable react-hooks/exhaustive-deps */
import { UserSessionData, useSwitchGroup } from '@benefits/auth-services';
import { UserContext } from '@benefits/common-services';
import { getUserAuthenticated } from '@benefits/common-utils';
import { BaseLayout, Loading, RenderFederatedModule } from '@benefits/components';
import { FeatureContext, useAuth2faNXP } from '@benefits/features';
import { useMessage } from '@benefits/message';
import { Auth as AuthRoutes, License, News } from '@benefits/router-services';
import { useClarityTracking } from '@benefits/tracking';
import { booleanFlag, featureFlagsResolver, listFlag } from '@ifood/feature-flags';
import CryptoJS from 'crypto-js';
import type { NextPage } from 'next';
import Router from 'next/router';
import { useContext, useEffect } from 'react';

import { MaintenanceMode } from '../components/Maintenance/Maintenance';
import { FeaturesToggle } from '../environment/featureToggles';
import { Auth } from '../templates/Auth/Auth';

type TogglesType = {
  toggles: {
    [key: string]: string[] | boolean;
  };
};

const LoginPage: NextPage<TogglesType> = ({ toggles }) => {
  const context = useContext(UserContext);
  const userStorage = getUserAuthenticated();
  const featureContext = useContext(FeatureContext);
  const { isAuth2faEnabled } = useAuth2faNXP();

  const { setCustomTag } = useClarityTracking();

  const user = context?.state?.user;
  const hasToken = user?.accessToken && userStorage?.accessToken;

  const authFactors = user?.authenticationFactors;
  const authenticationFactorsRequired = user?.authenticationFactorsRequired;
  const hasAuthenticationFactors = !!(authFactors && authFactors?.length > 0);
  const enabledSwitchGroup = Boolean(
    isAuth2faEnabled ? hasToken && !hasAuthenticationFactors && !authenticationFactorsRequired : hasToken
  );

  useSwitchGroup({
    onSuccess: async (data) => {
      const isMultiLicense = !data;
      if (data.email) incogniaSetAccountId(data.email);
      setCustomTag('multi_license', String(isMultiLicense));

      if (isMultiLicense) {
        context?.dispatch?.user({ ...user, multiLicense: isMultiLicense });

        if (isAuth2faEnabled) {
          Router.push(AuthRoutes.REGISTRATION_2FA);
        } else {
          Router.push(License.LIST);
        }
      } else {
        await featureContext?.dispatch?.refetch();
        context?.dispatch?.user({ ...data });

        if (isAuth2faEnabled) {
          Router.push(AuthRoutes.REGISTRATION_2FA);
        } else {
          Router.push(News.NEWS);
        }
      }
    },
    enabled: enabledSwitchGroup,
    onError: () => context?.dispatch?.clear(),
  });

  const incogniaSetAccountId = (email: string) => {
    const emailHash = CryptoJS.SHA256(email.trim().toLowerCase()).toString(CryptoJS.enc.Hex);
    window.IncogniaWebSdk.setAccountId(emailHash);
  };

  useMessage('authenticated', {
    onReceive: (message) => {
      const { accessToken, email, userId, userName, authenticationFactors, authenticationFactorsRequired } =
        message as UserSessionData;

      if (email) {
        incogniaSetAccountId(email);
      }

      context?.dispatch?.user({
        accessToken,
        email,
        userId,
        userName,
        authenticationFactors,
        authenticationFactorsRequired,
      });
    },
  });

  useEffect(() => {
    if (hasToken && isAuth2faEnabled && hasAuthenticationFactors) {
      Router.push(AuthRoutes.AUTHENTICATION_FACTORS_2FA);
    }
  }, [hasToken, isAuth2faEnabled, hasAuthenticationFactors]);

  useEffect(() => {
    if (hasToken && isAuth2faEnabled && !hasAuthenticationFactors && authenticationFactorsRequired) {
      Router.push(AuthRoutes.REGISTRATION_2FA);
    }
  }, [hasToken, isAuth2faEnabled, hasAuthenticationFactors, authenticationFactorsRequired]);

  if (toggles.UNDER_MAINTENANCE) {
    return <MaintenanceMode />;
  }

  if (!hasToken) {
    return (
      <BaseLayout>
        <Auth>{<RenderFederatedModule component="login" remote="auth" />}</Auth>
      </BaseLayout>
    );
  }

  return <Loading />;
};

//TODO: Review this implementation, if it's working as expected
export const getStaticProps = async () => {
  const resolver = featureFlagsResolver();
  const listFlagData = listFlag(FeaturesToggle.NEW_REPORT_EXPERIENCE, '');
  const underMaintenance = booleanFlag(FeaturesToggle.UNDER_MAINTENANCE, false);

  return {
    props: {
      toggles: {
        [FeaturesToggle.NEW_REPORT_EXPERIENCE]: resolver.getList(listFlagData),
        [FeaturesToggle.UNDER_MAINTENANCE]: resolver.isActive(underMaintenance),
      },
    },
    // Next.js will attempt to re-generate the page:
    // - When a request comes in
    // - At most once every 60 seconds
    revalidate: 60, /// In seconds
  };
};

export default LoginPage;
